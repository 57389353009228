import { hu } from './hu';

export namespace en {
  export const text: typeof hu.text = {
    ui: {
      enterNick: 'Nickname',
      join: 'Join a game',
      joinPrivate: 'Join private game',
      createPrivate: 'Create private game',
      'action-box': {
        exchanged: (cards: number) => `exchanged ${cards} cards`,
        bid: (bid: number | null, hold: boolean) => {
          const bidText = en.text.phase.bidding.bid(bid);
          return `bid: ${hold ? 'hold (' + bidText + ')': bidText }`
        },
        called: (trump: number) => `called: trump number ${trump}`,
        disconnected: 'disconnected',
        connected: 'connected',
        reconnected: 'reconnected',
        'tarots-exchanged': (numb: number) => `exchanged ${numb} ${numb === 1 ? 'tarock' : 'tarocks'}`,
        'trying-for-honor': 'without honor',
        table: 'Table',
        gameOver: 'party done'
      },
      'score-board': {
        scoreBoard: 'Scores',
        player: 'Player',
        partner: 'Partner',
        opponent: 'Opponent'
      },
      reportBug: {
        name: 'Bug Report',
        description: 'Bug Description',
      },
      send: 'Send',
      close: 'Close',
      loadingAssets: 'Loading assets...',
      loadingGame: 'Waiting for players...',
      dealer: 'Dealer',
      player: 'Player',
      contact: 'Contact',
      userNameTooLong: (min: number, max: number) => `Username is too long, has to be between ${min} and ${max} characters`,
      userNameTooShort: (min: number, max: number) => `Username is too short, has to be between ${min} and ${max} characters`,
      genericError: 'Unexpected error',
      version: 'Version',
      rotateMessage: 'Increase the size of the window, or rotate your screen!',
      invite: {
        text: 'Invite players',
        copy: 'Copy',
        share: 'Share',
        description: 'Share this link to invite other players:'
      },
      reconnectModal: {
        title: 'Reconnect',
        exit: 'Quit',
        message: 'The connection was lost. Would you like to try to reconnect?',
        ok: 'Reconnect'
      }
    },
    phase: {
      bidding: {
        bid: (bid: number | null) => {
          const bidTexts = ['solo', 'one', 'two', 'three'];
          return bid === null ? 'pass' : bidTexts[bid];
        },
        hold: (numBid: number) => {
          const bidText = en.text.phase.bidding.bid(numBid);
          return `hold (${bidText})`
        }
      },
      exchanging: {
        endExchange: "exchange",
        throw: 'throw'
      },
      partnerCalling: {
        callPartner: "Call partner"
      },
      figures: {
        figs: {
          volat: 'Volat',
          contra: 'Contra',
          party: 'Party',
          trull: 'Tous les trois',
          ulti: 'Ulti',
          'double-play': 'Double Play',
          'eight-trumps': '8 Tarots',
          'four-kings': '4 Kings',
          'nine-trumps': '9 Tarots',
          'xxi-catch': '21 Catch'
        },
        mandatory: 'Mandatory',
        contra: (depth: number) => ['Contra', 'Recontra', 'Subcontra', 'Hirschcontra'][depth - 1],
        pass: 'pass',
        silent: 'Silent',
      }
    }
  };
}
