export let CONST = {
  SCORE: 0,
  HIGHSCORE: 0,
  MAX_CONTRA_DEPTH: 4,
  PLAY_CARD_DELAY: 1000 /* ms */,
  NEW_GAME_DELAY: 15000 /* ms */,
  AUDIO_FOLDER: '/assets/sounds',
  IMAGE_FOLDER: '/assets/cards',
  CONTACT_MAIL: 'admin@tarokkonline.hu',
  PING_PONG_INTERVAL_MS: 10000,
  PING_PONT_JITTER_MS: 1250,
};
