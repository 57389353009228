
  import {Component, Prop, Vue} from 'vue-property-decorator';

  @Component
  export default class LoadingScreen extends Vue {
    @Prop() loadingText!: string;
    @Prop() loading!: boolean;
    @Prop() loadingPromise!: Promise<never>;

    created() {
      if (this.loadingPromise) {
        this.loading = true;
        this.loadingPromise.finally(() => {
          this.loading = false;
        })
      }
    }
  }
