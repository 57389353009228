
  import UiButton from "./Button.vue";
  import {Component, Inject, Prop, Vue} from "vue-property-decorator";
  import {ExchangingState, UiController} from "../control/uiController";
  import {LanguageConfig} from '../language/languages';
  import {GameController} from '../control/gameController';

  @Component({
    components: {
      UiButton,
    },
  })
  export default class ExchangeButton extends Vue {
    @Inject() lc!: LanguageConfig;
    @Inject() gameController!: GameController;
    @Prop({required: true}) exchangingState!: ExchangingState;
  }
