
  import {Component, Inject, Prop, Vue} from 'vue-property-decorator';
  import {LanguageConfig} from '../language/languages';
  import Button from './Button.vue';

  @Component({
    components: {Button},
  })
  export default class PrivateGameInviter extends Vue {
    @Inject() lc!: LanguageConfig;
    @Prop({required: true}) url!: string;

    mounted() {
      this.$bvModal.show('invite-modal');
    }

    get canShare() {
      return navigator && navigator.share;
    }

    copy() {
      function fallbackCopyTextToClipboard(text: string, input: HTMLInputElement) {
        input.focus()
        input.select();

        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Unable to copy', err);
        }

        // document.body.removeChild(textArea);
      }

      function copyTextToClipboard(text: string, input: HTMLInputElement) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text, input);
          return;
        }
        navigator.clipboard.writeText(text)
          .catch(err => console.error('Unable to copy', err));
      }

      const input = document.querySelector('#url-input') as HTMLInputElement;
      if (!input || input['select'] === undefined) {
        console.error('Unable to copy', '#url-input doesn\'t exist');
      }
      copyTextToClipboard(this.url, input);
    }

    selectAll() {
      const input = document.querySelector('#url-input') as HTMLInputElement;
      if (!input || input['select'] === undefined) {
        console.error('Unable to select', '#url-input doesn\'t exist');
      }
      input.focus();
      input.select();
    }

    share() {
      navigator.share({
        title: this.lc.text.ui.invite.text,
        url: this.url
      }).catch((error) => console.error('Error sharing', error));
    }
  }
