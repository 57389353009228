import {Phase, Scores} from '../data/gamestate';
import {Card, Suite} from '../data/card';
import {FigureStateHandler} from "./figureStateHandler";
import {GameResponse} from '../model/gameResponse';
import BiddingResult = GameResponse.BiddingResult;

export class BiddingState {
  private readonly _availableBids: BiddingResult[] = [];

  get availableBids() {
    return this._availableBids;
  }

  set availableBids(bids: BiddingResult[]) {
    this.availableBids.splice(0, this.availableBids.length, ...bids);
  }
}

export class ExchangingState {
  private readonly _selectedCardsForExchange: Card[] = [];
  private _numberOfCardsToSelect: number = 0;
  private _canThrow: boolean = false;

  get selectedCardsForExchange() {
    return this._selectedCardsForExchange;
  }

  set selectedCardsForExchange(cards) {
    this._selectedCardsForExchange.splice(0,
      this._selectedCardsForExchange.length, ...cards);
  }

  get numberOfCardsToSelect(): number {
    return this._numberOfCardsToSelect;
  }

  set numberOfCardsToSelect(value: number) {
    this._numberOfCardsToSelect = value;
  }

  get doneExchanging() {
    return this._numberOfCardsToSelect === this._selectedCardsForExchange.length;
  }

  isCardSelectedForExchange(card: Card) {
    return this._selectedCardsForExchange.findIndex(c => c.equals(card)) > -1;
  }

  removeSelectedCardForExchange(card: Card) {
    const index = this._selectedCardsForExchange.findIndex(c => c.equals(card));
    if (index > -1) {
      this._selectedCardsForExchange.splice(index, 1);
    }
  }

  get canThrow(): boolean {
    return this._canThrow;
  }

  set canThrow(value: boolean) {
    this._canThrow = value;
  }
}

export class FigureState {
  private readonly _figureStateHandler: FigureStateHandler = new FigureStateHandler();

  get figureStateHandler() {
    return this._figureStateHandler;
  }
}

export class PartnerCallingState {
  private _partnerToCall: Card[] = [new Card(Suite.Tarock, 20)];

  get partnerToCall(): Card[] {
    return this._partnerToCall;
  }

  set partnerToCall(value: Card[]) {
    this._partnerToCall.splice(0, this._partnerToCall.length, ...value);
  }
}

export class GameOverState {
  private _scores: Scores | null = null;

  get scores() {
    return this._scores;
  }

  set scores(value: Scores | null) {
    this._scores = value;
  }
}

export class EmptyState<T extends 'pre-game' | 'main' | 'waiting'> {
  kind: T;

  constructor(kind: T) {
    this.kind = kind;
  }
}

type PhaseStates = {
  'pre-game': EmptyState<'pre-game'>,
  main: EmptyState<'main'>,
  bidding: BiddingState,
  exchanging: ExchangingState
  figures: FigureState
  'partner-calling': PartnerCallingState,
  waiting: EmptyState<'waiting'>,
  'game-over': GameOverState,
}

export class UiController {
  private static makeInitialState(): PhaseStates {
    return {
      'pre-game': new EmptyState('pre-game'),
      main: new EmptyState('main'),
      bidding: new BiddingState(),
      exchanging: new ExchangingState(),
      figures: new FigureState(),
      'partner-calling': new PartnerCallingState(),
      waiting: new EmptyState('waiting'),
      'game-over': new GameOverState(),
    };
  }
  private _allPhaseStates: PhaseStates = UiController.makeInitialState();

  private _currentPhase: Phase['kind'] = 'pre-game';

  get currentPhase() {
    return this._currentPhase;
  }

  set currentPhase(phase: Phase['kind']) {
    this._currentPhase = phase;
  }

  getState<K extends keyof PhaseStates>(t: K): PhaseStates[K] {
    return this._allPhaseStates[t];
  }

  reset() {
    this._allPhaseStates.bidding.availableBids = [];

    this._allPhaseStates.exchanging.selectedCardsForExchange = [];
    this._allPhaseStates.exchanging.numberOfCardsToSelect = 0;
    this._allPhaseStates.exchanging.canThrow = false;
  }
}