
  import {Component, Inject, Prop, Vue} from "vue-property-decorator";
  import rasterizeHTML from "rasterizehtml";
  import {LanguageConfig} from "../../game/tarock/language/languages";
  import {globals} from "../../env";

  @Component
  export default class BugReporter extends Vue {
    @Inject() lc!: LanguageConfig;
    bugDescription: string = '';
    bugImageData: string = '';

    private async takeScreenshot() {
      const canvas = document.createElement('canvas');
      canvas.setAttribute('width', '' + window.innerWidth);
      canvas.setAttribute('height', '' + window.innerHeight);
      try {
        await rasterizeHTML.drawDocument(document, canvas);
      } catch (ex) {
        console.warn(ex);
      }
      return canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, '');
    }

    async show() {
      this.bugImageData = await this.takeScreenshot();
      this.$bvModal.show('bug-report-modal');
    }

    private async sendReport() {
      if (this.bugDescription === '' && this.bugImageData === '') {
        return;
      }

      await fetch(globals.SERVER_URL + 'report-bug', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          description: this.bugDescription,
          screenshot: this.bugImageData,
        })
      });
    }
  }
