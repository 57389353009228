
  import {Component, Inject, Prop, Vue} from 'vue-property-decorator';
  import {GameController} from '../control/gameController';
  import {mergeMap, tap} from 'rxjs/operators';

  @Component
  export default class SoundManager extends Vue {
    @Inject() gameController!: GameController;
    @Prop({required: true}) audioElements!: {[effect: string]: HTMLAudioElement};

    created() {
      const stream = this.gameController.gameResponseStream
        .pipe(
          mergeMap(x => x),
          tap(action => {
            if (action.$type === 'TransparentPlayerActionWithId') {
              switch (action.action.$type) {
                case 'LastExchanged':
                case 'OtherExchanged':
                case 'MeExchanged':
                  this.playSound('card_flip');
                  break;
                case 'Played':
                  this.playSound('card_flip');
                  break;
                case 'CalledFriend':
                case 'Said':
                case 'Bid':
                  this.playSound('action');
                  break;
              }
            } else if (action.$type === 'GameStarted') {
              this.playSound('shuffle');
            } else if (action.$type === 'GameStep') {
              if (action.transition.$type === 'EndBidding') {
                this.playSound('card_flip');
              }
            }
          })
        );

      this.$subscribeTo(stream, () => {});
    }

    private playSound(effect: string) {
      if (this.gameController.isReplaying) {
        return;
      }

      const audio = this.audioElements[effect];
      if (!audio) {
        console.warn(effect + ' is not loaded');
        return;
      }
      audio.play();
    }
  }
