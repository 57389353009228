
  import {Component, Inject, Prop, Vue} from "vue-property-decorator";
  import {LanguageConfig, LC} from '../../game/tarock/language/languages';
  import {globals} from '../../env';
  import {SharedStore} from '../../game/tarock/control/sharedStore';
  import {CONST} from '../../game/tarock/const/const';
  import {Dictionary, RawLocation} from 'vue-router/types/router';

  type UserError =
    | { $type: 'NameTooLong', min: number, max: number }
    | { $type: 'NameTooShort', min: number, max: number }

  function isUserError(value: any): value is UserError {
    return value && ['NameTooLong', 'NameTooShort'].includes(value.$type);

  }

  @Component
  export default class LandingPage extends Vue {
    @Inject() readonly lc!: LanguageConfig;
    @Inject() readonly sharedStore!: SharedStore;

    @Prop({required: false}) id!: string;

    playerName: string = '';
    placeholderShowing = true;
    loading = true;
    errorText: ((lc: LC) => string) | null = null;
    version: string = '';

    private contactMail = CONST.CONTACT_MAIL;

    created() {
      this.fetchLoggedInState();
      this.fetchVersion();
      this.sharedStore.isPrivateGameCreator = false;
    }

    private async fetchLoggedInState() {
      const response = await fetch(globals.SERVER_URL + 'is-logged-in', {
        credentials: 'include',
        method: 'GET'
      });
      if (response.ok) {
        const userName = await response.json();
        this.loading = false;
        this.playerName = userName;
        this.placeholderShowing = false;
      } else {
        this.loading = false;
      }
    }

    private async fetchVersion() {
      const response = await fetch(globals.SERVER_URL + 'version', { credentials: 'include' });
      if (response.ok) {
        this.version = await response.text();
      }
    }

    private async login() {
      return await fetch(globals.SERVER_URL + 'login', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({name: this.playerName})
      });
    }

    private async gotoGame(response: Response, next: RawLocation) {
      if (response.ok) {
        this.errorText = null;
        await this.$router.push(next);
      } else {
        await this.handleError(response);
      }
    }

    private async handleError(response: Response) {
      if (!response.ok) {
        try {
          const err = await response.json();
          this.showUserError(err);
        } catch (err) {
          console.error(err);
          this.showUserError(null);
        }
        return false;
      }
      return true;
    }

    async join() {
      this.sharedStore.userInteracted = true;
      const response = await this.login();
      if (this.isPrivateGame) {
        await this.gotoGame(response, { path: `/game/${this.id}`});
      } else {
        await this.gotoGame(response, '/game');
      }
    }

    async createPrivate() {
      this.sharedStore.userInteracted = true;
      const loginResponse = await this.login();
      if (!await this.handleError(loginResponse)) {
        return;
      }
      const idResponse = await fetch(globals.SERVER_URL + 'game/create-table', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!await this.handleError(loginResponse)) {
        return;
      }

      const json = await idResponse.json();
      const id = json.id;
      if (!json.id || typeof json.id !== 'string') {
        this.errorText = lc => lc.ui.genericError;
        return;
      }

      this.errorText = null;
      this.sharedStore.isPrivateGameCreator = true;
      await this.$router.push({ path: `/game/${id}`});
    }

    private showUserError(err: any) {
      this.playerName = '';
      const input = this.$refs['player-name'] as HTMLElement;
      if (input) {
        input.focus();
      }

      if (isUserError(err)) {
        switch (err.$type) {
          case 'NameTooLong':
            this.errorText = lc => lc.ui.userNameTooLong(err.min, err.max);
            break;
          case 'NameTooShort':
            this.errorText = lc => lc.ui.userNameTooShort(err.min, err.max);
            break;
        }
        return;
      }
      this.errorText = lc => lc.ui.genericError;
    }

    submitOnEnter(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.join();
      }
    }

    get isPrivateGame(): boolean {
      return !!this.id;
    }
  }
