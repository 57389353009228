
  import {Component, Prop, Vue} from 'vue-property-decorator';
  import {Card} from '../../data/card';
  import {CONST} from "../../const/const";

  @Component
  export default class CardComponent extends Vue {
    @Prop() card!: Card | null;
    @Prop({default: false}) draggable!: boolean;
    @Prop({type: Boolean}) small!: boolean;
    windowHeight: number = 0;
    windowWidth: number = 0;

    private maxCardSize = { width: 96, height: 164 };
    private minCardSize = { width: 47, height: 80 };
    private scaleStop = { width: 1720, height: 900 };
    private scaleStart = { width: 734, height: 524 };

    created() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      window.addEventListener('resize', this.onResize);
      this.onResize();
    }

    mounted() {
      this.onResize();
    }

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    }

    get assetName() {
      return this.card === null ? `${CONST.IMAGE_FOLDER}/card_back.png` : this.card.makeAssetName();
    }

    get alt() {
      return this.card === null ? 'card_back' : this.card.key;
    }

    private onResize() {
      const img = this.$refs['card-img'] as HTMLElement;
      if (!img) {
        return;
      }

      this.$emit('size-changed', {width: img.clientWidth, height: img.clientHeight});
    }
  }
