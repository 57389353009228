
  import {Component, Inject, Prop, Vue, Watch} from "vue-property-decorator";
  import {Scores} from "../data/gamestate";
  import {LanguageConfig, LC} from "../language/languages";
  import {Figure} from "../data/figure";
  import figureId = Figure.figureId;
  import {CONST} from "../const/const";
  import UiButton from "./Button.vue";
  import {UserRepo} from '../control/userRepo';

  @Component({
    components: {
      UiButton,
    },
  })
  export default class ScoreBoard extends Vue {
    @Inject() readonly lc!: LanguageConfig;
    @Inject() readonly userRepo!: UserRepo;
    @Prop({required: true}) scoreBoard!: Scores | null;

    timeBeforeNextGame: number = CONST.NEW_GAME_DELAY;
    private timerHandle: number | null = null;

    mounted() {
      this.showModalOnScoreChange();
    }

    beforeDestroy() {
      this.$bvModal.hide('score-board-modal');
    }

    @Watch('scoreBoard')
    private showModalOnScoreChange() {
      if (this.scoreBoard !== null) {
        this.startCountdown();
        this.$bvModal.show('score-board-modal');
      }
    }

    private startCountdown() {
      if (this.timerHandle !== null) {
        window.clearInterval(this.timerHandle);
        this.timerHandle = null;
      }
      this.timeBeforeNextGame = CONST.NEW_GAME_DELAY;
      let start = Date.now();

      this.timerHandle = window.setInterval(() => {
        if (this.timeBeforeNextGame > 0) {
          const now = Date.now();
          this.timeBeforeNextGame -= now - start;
          start = now;
        } else {
          if (this.timerHandle !== null) {
            window.clearInterval(this.timerHandle);
            this.timerHandle = null;
          }
        }
      }, 250);

    }

    get timeoutText() {
      const minutes = Math.max(0, Math.floor(this.timeBeforeNextGame / 60000));
      const seconds = Math.max(0, (this.timeBeforeNextGame % 60000) / 1000);
      const fixedSeconds = parseInt(seconds.toFixed(0));
      return (minutes < 10 ? '0' : '') + minutes + ":" + (fixedSeconds < 10 ? '0' : '') + fixedSeconds;
    }

    get titleText() {
      return this.lc.text.ui['score-board'].scoreBoard + ' (' + this.timeoutText + ')';
    }

    private sortedScores(scoreBoard: Scores) {
      return [...scoreBoard.scoreTable].sort((a, b) => {
        const pl = scoreBoard.player;
        return Math.abs(b.scores[pl]) - Math.abs(a.scores[pl]);
      });
    }

    private figureToText(figure: Figure, silent: boolean, lc: LC) {
      if (figure.kind === 'contra') {
        return lc.phase.figures.contra(figure.contraCount) + ' ' +
          lc.phase.figures.figs[figure.baseFigure.kind];
      }

      return (silent ? lc.phase.figures.silent + ' ' : '') +
        lc.phase.figures.figs[figure.kind];
    }

    private renderPlusMinus(score: number) {
      return score > 0 ? '+' + score : '' + score;
    }

    private figureId(figure: Figure) {
      return figureId(figure);
    }

    get isThreeVsOne() {
      return this.scoreBoard?.opponents.length === 3;
    }

    get sortedPlayerIds() {
      if (!this.scoreBoard) {
        return [];
      }

      return [
        this.scoreBoard.player,
        this.scoreBoard.partner === null ? this.scoreBoard.opponents[2] : this.scoreBoard.partner,
        this.scoreBoard.opponents[0],
        this.scoreBoard.opponents[1],
      ]
    }

    get tally() {
      const result = {
        playerTotal: 0,
        enemyTotal: 0,
      };
      if (!this.scoreBoard) {
        return result;
      }
      const resultTable = {}
      resultTable[this.scoreBoard.player] = 'playerTotal';

      this.scoreBoard.scoreTable.forEach(value => {
        result.playerTotal += value.scores[this.scoreBoard!.player];
        result.enemyTotal += value.scores[this.scoreBoard!.opponents[0]];
      });

      return result;
    }
  }
