
  import {Component, Inject, Prop, Vue} from "vue-property-decorator";
  import UiButton from './Button.vue';
  import {LanguageConfig} from '../language/languages';
  import {GameController} from '../control/gameController';
  import {Card} from '../data/card';

  @Component({
    components: {
      UiButton,
    },
  })
  export default class CallPartner extends Vue {
    @Inject() lc!: LanguageConfig;
    @Inject() gameController!: GameController;
    @Prop({required: true}) partnerToCall!: Card[];

    private readonly romanNumberTable = {
      20: 'XX',
      19: 'XIX',
      18: 'XVIII',
      17: 'XVII',
      16: 'XVI',
      15: 'XV',
      14: 'XIV',
      13: 'XIII',
      12: 'XII',
      11: 'XI',
      10: 'X',
      9: 'IX',
      8: 'VIII',
      7: 'VII',
      6: 'VI',
      5: 'V',
      4: 'IIII',
      3: 'III',
      2: 'II',
      1: 'I',
    };

    romanText(card: Card) {
      return this.romanNumberTable[card.rank];
    }

    private sortPartners(partners: Card[]) {
      return [...partners].sort((a, b) => b.rank - a.rank);
    }
  }
