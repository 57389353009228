
  import {Component, Inject, Prop, Vue, Watch} from 'vue-property-decorator';
  import EnemyHand from './EnemyHand.vue';
  import {Exchanging, GameState, Phase, PlayerPosition, StateData} from '../../data/gamestate';
  import PlayerBox from './PlayerBox.vue';
  import {GameController} from '../../control/gameController';
  import {UiController} from '../../control/uiController';
  import {Card, Suite} from '../../data/card';
  import CardComponent from './CardComponent.vue';
  import {getOrThrow, nullArray} from "../../utils/utils";
  import {CONST} from "../../const/const";
  import {LanguageConfig, LC} from "../../language/languages";
  import Hand from "./Hand.vue";
  import {CardState} from "./Hand.vue";

  @Component({
    components: {
      PlayerBox,
      Hand,
      EnemyHand,
      CardComponent,
    }
  })
  export default class GameTable extends Vue {
    @Inject() lc!: LanguageConfig;
    @Inject() gameController!: GameController;
    @Prop({required: true}) uiController!: UiController;
    @Prop() bubbles!: { [playerId: string]: (lc: LC) => string };
    gameState: GameState<Phase> | null = null;
    hands: { [position: string]: Card[] | null[] } = {
      top: [],
      right: [],
      left: [],
      bottom: [],
    };
    activeCards: Card[] = [];
    cardsOnTable: { position: PlayerPosition, card: Card }[] = [];
    playerExchanged: Array<Card | null> = [];
    othersExchanged: PlayerPosition[] = [];
    playerPosition: PlayerPosition | '' = '';
    dealerPosition: PlayerPosition | '' = '';

    private myLastCard: Card | undefined = undefined;
    private takes: PlayerPosition | null = null;
    private readonly positions: PlayerPosition[] = ['bottom', 'top', 'right', 'left'];
    private readonly positionOrder: PlayerPosition[] = ['bottom', 'right', 'top', 'left', 'bottom', 'right', 'top'];
    private talonDistribution: Array<PlayerPosition | null> = nullArray(6);
    private readonly waitingStates = ['waiting', 'pre-game'];

    created() {
      this.$subscribeTo(this.gameController.gameUpdated, state => {
        const phase = state.phase;
        this.uiController.currentPhase = phase.kind;
        switch (phase.kind) {
          case 'pre-game':
            this.resetState();
            break;
          case 'waiting':
            this.$emit('game-running', false);
            break;
          case 'bidding':
            this.$emit('game-running', true);
            this.uiController.getState('bidding').availableBids = phase.availableBids ?? [];
            break;
          case 'exchanging':
            this.updateExchangingPositions(state.state, phase.player);
            this.updateExchangedCards(state.state);
            this.setTalonDistribution(state.state);

            const exchUiState = this.uiController.getState('exchanging');
            exchUiState.numberOfCardsToSelect = state.state.hand.length - 9;
            exchUiState.selectedCardsForExchange = [];
            exchUiState.canThrow = phase.canThrow;
            const thisPlayer = this.gameController.thisPlayer;
            if (thisPlayer && this.gameState?.state.exchanged[thisPlayer]) {
              exchUiState.numberOfCardsToSelect = -1;
              this.activeCards.splice(0, this.activeCards.length);
              this.uiController.currentPhase = 'waiting';
            }
            break;
          case 'partner-calling':
            const partnerCallingState = this.uiController.getState('partner-calling');
            partnerCallingState.partnerToCall = phase.validPartners;
            break;
          case 'figures':
            this.uiController.getState('figures').figureStateHandler.figures = phase;
            break;
          case 'main':
            const isMyTurn = phase.playable.length > 0;

            if (phase.takes !== null) {
              const takesPosition = Object.keys(state.state.positions)
                .find(key => state.state.positions[key] === phase.takes);
              this.takes = takesPosition as PlayerPosition;
            }

            this.activeCards.splice(0, this.activeCards.length, ...phase.playable);
            const positionOrder: PlayerPosition[] = this.getPlayCardPositionsWithOffset(state.state);

            if (this.cardsOnTable.length === 4 && !isMyTurn) {
              this.resetTable();
            }

            positionOrder
              .forEach(pos => {
                if (this.cardsOnTable.findIndex(c => c.position === pos) === -1) {
                  const card = state.state.cardsOnTable[state.state.positions[pos]];
                  if (card) {
                    this.cardsOnTable.push({position: pos, card: card})
                  }
                }
              });

            const cardToRemove = state.state.cardsOnTable[this.gameController.thisPlayer ?? ''];
            if (cardToRemove && !isMyTurn) {
              const indexOf = this.gameState?.state.hand.findIndex(c => c.equals(cardToRemove)) ?? -1;
              if (indexOf > -1) {
                this.myLastCard = this.gameState?.state.hand.splice(indexOf, 1)[0];
              }
            }

            if (this.cardsOnTable.length === 4 && !isMyTurn) {
              const delay = this.gameController.isReplaying ? 0 : CONST.PLAY_CARD_DELAY;
              this.gameController.delayBy(delay);
              if (state.state.hand.length === 0) {
                setTimeout(() => {
                  this.resetTable();
                }, delay);
              }
            }
            break;
          case 'game-over':
            if (phase.scores) {
              this.uiController.getState('game-over').scores = phase.scores;
              this.gameController.delayBy(CONST.NEW_GAME_DELAY);
            }
            break;
        }

        this.gameState = state
      });
    }

    private resetTable() {
      this.playerExchanged.splice(0, this.playerExchanged.length,
        ...nullArray(this.playerExchanged.length));
      this.cardsOnTable.splice(0, this.cardsOnTable.length);
    }

    private resetState() {
      this.uiController.reset();
      this.cardsOnTable.splice(0, this.cardsOnTable.length);
      this.activeCards.splice(0, this.activeCards.length);
      Object.keys(this.hands).forEach(k => {
        this.hands[k].splice(0, this.hands[k].length);
      });

      this.takes = null;
      this.playerExchanged.splice(0, this.playerExchanged.length);
      this.othersExchanged = [];
      this.talonDistribution.splice(0, this.talonDistribution.length, ...nullArray(6));
    }

    private getPlayerPosition(playerId: string, state: StateData): PlayerPosition {
      return getOrThrow(Object.keys(state.positions).find(
        idx => state.positions[idx] === playerId,
      ), 'playerPosition') as PlayerPosition;
    }

    private updateExchangingPositions(state: StateData, playerId: string) {
      const playerPosition = this.getPlayerPosition(playerId, state);
      const dealerPosition = this.getPlayerPosition(state.dealer, state);

      this.playerPosition = playerPosition as PlayerPosition;
      this.dealerPosition = dealerPosition as PlayerPosition;
    }

    private updateExchangedCards(state: StateData) {
      const positions = Object.entries(state.exchanged)
        .filter(([playerId, e]) => playerId !== state.callingPlayer)
        .map(([playerId, e]) => this.getPlayerPosition(playerId, state))
        .filter(pos => !this.othersExchanged.includes(pos));

      positions.forEach(pos => {
        const exchanged = state.exchanged[state.positions[pos]];
        for (let i = 0; i < exchanged.exchanged; i++) {
          this.othersExchanged.push(pos);
        }
      });

      this.playerExchanged.splice(0, this.playerExchanged.length, ...state.playerExchanged);
    }

    private rotatedPositions(baseline: PlayerPosition) {
      const posOrderStart = this.positionOrder.indexOf(baseline);
      return this.positionOrder.slice(posOrderStart, posOrderStart + 4);
    }

    private getPlayCardPositionsWithOffset(state: StateData) {
      const firstPosition = state.positions[Object.keys(state.cardsOnTable)[0]] ?? 'bottom';
      const offsetBase = this.cardsOnTable.length > 0 ? this.cardsOnTable[0].position : firstPosition;
      return this.rotatedPositions(offsetBase);
    }

    isDealer(position: PlayerPosition) {
      const playerAtPos: string | undefined = this.gameState?.state.positions[position];
      return this.gameState?.state.dealer === playerAtPos;
    }

    cardsInHand(position: PlayerPosition) {
      return this.hands[position];
    }

    @Watch('gameState', {deep: true})
    private updateHands() {
      if (!this.gameState || !this.gameState.state) {
        return;
      }

      this.positions.forEach(position => {
        if (position === 'bottom') {
          this.hands[position].splice(0, this.hands[position].length, ...this.gameState!.state.hand);
          return;
        }

        const playerAtPos: string | undefined = this.gameState?.state?.positions[position];
        if (!playerAtPos) {
          return;
        }
        const num = this.gameState?.state.handCounts[playerAtPos];
        if (num === undefined) {
          return;
        }
        this.hands[position].splice(0, this.hands[position].length, ...nullArray(num));
      })
    }

    @Watch('gameState', {deep: true})
    private updateActiveCards() {
      if (this.gameState && this.gameState.phase.kind === 'exchanging') {
        const thisPlayer = this.gameController.thisPlayer;
        if (thisPlayer && this.gameState?.state.exchanged[thisPlayer]) {
          return false;
        }
        this.setActiveCardsFrom(this.gameState.phase);
      }
    }

    private setActiveCardsFrom(phase: Exchanging) {
      this.activeCards.splice(0, this.activeCards.length, ...phase.exchangeable);
    }

    cardClicked(card: CardState) {
      if (this.gameState && this.gameState.phase.kind === 'exchanging') {
        const exchanging = this.uiController.getState('exchanging');

        if (exchanging.doneExchanging && !card.stick) {
          return;
        }

        if (card.stick) {
          exchanging.removeSelectedCardForExchange(card.card!);
          this.setActiveCardsFrom(this.gameState.phase);
        } else {
          exchanging.selectedCardsForExchange.push(card.card!);
          if (exchanging.doneExchanging) {
            this.activeCards.splice(0, this.activeCards.length);
          }
        }
        card.stick = !card.stick;
      }

      if (this.gameState && this.gameState.phase.kind === 'main') {
        this.gameController.playCard(card.card!);
      }
    }

    playCardBeforeEnter(el: HTMLElement) {
      const pos = el.dataset.position as PlayerPosition;
      const box = document.querySelector('.player--' + pos + ' .hand');

      let ch: HTMLElement | undefined;
      if (pos === 'bottom') {
        ch = box?.querySelector('img[alt="' + el.getAttribute('alt') + '"]') as HTMLElement;
      } else {
        ch = box?.lastChild as HTMLElement;
      }

      if (!ch) {
        return;
      }

      ch.getBoundingClientRect();
      el.style.top = ch.offsetTop + 'px';
      el.style.left = ch.offsetLeft + 'px';
      el.style.transform = 'none';

      requestAnimationFrame(() => {
        el.style.top = '';
        el.style.left = '';
        el.style.transform = '';
      });
    }

    playCardBeforeLeave(el: HTMLElement) {
      if (!this.takes) {
        return;
      }

      const size = 1.5 * el.clientHeight;

      el.style.transform = '';
      switch (this.takes) {
        case "right":
          el.style.left = (window.innerWidth + size) + 'px';
          break;
        case "top":
          el.style.top = -size + 'px';
          break;
        case "left":
          el.style.left = -size + 'px';
          break;
        case "bottom":
          el.style.top = (window.innerHeight + size) + 'px';
          break;
      }
    }

    private setTalonDistribution(state: StateData) {
      if (this.playerPosition === '') {
        return;
      }

      const offsetBase = this.playerPosition;
      const distributeTo = this.rotatedPositions(offsetBase);

      const talonDistribution = distributeTo.map(pos => {
        const player = state.positions[pos];
        const x = state.handCounts[player];
        return x - 9;
      });

      let cardCount = 6;
      let i = 0;
      while (cardCount >= 0) {
        if (talonDistribution[i] === 0) {
          i++;
        } else {
          const idx = 6 - cardCount;
          cardCount -= 1;
          talonDistribution[i] -= 1;
          if (idx < this.talonDistribution.length) {
            this.talonDistribution.splice(idx, 1, distributeTo[i]);
          }
        }
      }
    }

    distributeTalonLeave(el, done: () => void) {
      const i = el.dataset.index as number;
      const pos = this.talonDistribution[5 - i];

      const box = document.querySelector('.player--' + pos + ' .hand');
      if (!box) {
        done();
        return;
      }

      const rect = box.getBoundingClientRect();
      const middleX = rect.left + rect.width / 2;
      const middleY = rect.top;

      const elRect = el.getBoundingClientRect();
      const x = middleX - elRect.left - elRect.width / 2;
      const y = middleY - elRect.top;

      setTimeout(() => {
        el.style.transform = `translate(${x}px, ${y}px)`;
        el.addEventListener('transitionend', function animend() {
          el.removeEventListener('transitionend', animend);
          done();
        });
      }, (5 - i) * 200);
    }

    distributeTalonBeforeLeave(el: HTMLElement) {
      el.style.transform = 'translate(0, 0)';
    }

    cardExchangedBeforeEnter(basePosition: PlayerPosition, targetPosition: PlayerPosition, targetSel: 'player' | 'others', el) {
      const target = document.querySelector(`.by-${targetSel}.exchanged-cards.${basePosition}`);
      const box = document.querySelector('.player--' + targetPosition + ' .hand');
      if (!box || !target) {
        return;
      }

      const rect = box.getBoundingClientRect();
      const middleX = rect.left + rect.width / 2;
      const middleY = rect.top;

      const targetRect = target.getBoundingClientRect();
      const x = middleX - targetRect.left - targetRect.width;
      const y = middleY - targetRect.top;

      el.style.position = 'relative';
      el.style.left = x + 'px';
      el.style.top = y + 'px';

      requestAnimationFrame(() => {
        el.style.top = '0';
        el.style.left = '0';
      })
    }

    othersCardExchangedBeforeEnter(el) {
      if (this.dealerPosition === '') {
        return;
      }
      const pos = el.dataset.position;
      this.cardExchangedBeforeEnter(this.dealerPosition, pos, 'others', el);
    }

    playerCardExchangedBeforeEnter(el) {
      if (this.playerPosition === '') {
        return;
      }
      this.cardExchangedBeforeEnter(this.playerPosition, this.playerPosition, 'player', el);
    }

    bubbleText(mk: ((lc: LC) => string) | undefined) {
      if (!mk) {
        return '';
      }
      return mk(this.lc.text);
    }
  }
