
  import {Component, Inject, Prop, Vue} from "vue-property-decorator";
  import {LanguageConfig, LC} from '../../game/tarock/language/languages';
  import {GameController} from '../../game/tarock/control/gameController';
  import Bidding from '../../game/tarock/ui/Bidding.vue';
  import {Phase} from '../../game/tarock/data/gamestate';
  import ExchangeButton from '../../game/tarock/ui/ExchangeButton.vue';
  import {UiController} from '../../game/tarock/control/uiController';
  import FigureButtons from '../../game/tarock/ui/FigureButtons.vue';
  import ActionBox, {Line} from '../../game/tarock/ui/ActionBox.vue';
  import {globals} from '../../env';
  import {SubscriptionLike} from 'rxjs';
  import CallPartner from '../../game/tarock/ui/CallPartner.vue';
  import GameTable from '../../game/tarock/ui/game/GameTable.vue';
  import ScoreBoard from "../../game/tarock/ui/ScoreBoard.vue";
  import LoadingScreen from '../../game/tarock/ui/LoadingScreen.vue';
  import {Card} from '../../game/tarock/data/card';
  import {Deck} from '../../game/tarock/data/deck';
  import SoundManager from '../../game/tarock/ui/SoundManager.vue';
  import {CONST} from '../../game/tarock/const/const';
  import {SharedStore} from '../../game/tarock/control/sharedStore';
  import BugReporter from './BugReporter.vue';
  import PrivateGameInviter from '../../game/tarock/ui/PrivateGameInviter.vue';

  type Comps = {
    [P in Phase['kind']]?: Vue.Component<any, any, any, any>
  }

  const emptyComponent = Vue.extend({template: '<span></span>'});

  const components: Comps = {
    'pre-game': emptyComponent,
    bidding: Bidding,
    exchanging: ExchangeButton,
    figures: FigureButtons,
    'partner-calling': CallPartner,
    'waiting': emptyComponent,
    'game-over': ScoreBoard,
  };

  const allComponents = {
    ...components,
    ActionBox,
    GameTable,
    LoadingScreen,
    SoundManager,
    BugReporter,
    PrivateGameInviter,
  };

  @Component({
    components: allComponents as any,
  })
  export default class Game extends Vue {
    @Inject() readonly lc!: LanguageConfig;
    @Inject() readonly gameController!: GameController;
    @Inject() readonly sharedStore!: SharedStore;

    @Prop({required: false}) id!: string;

    private readonly uiController = new UiController();

    private bubbles: {[playerId: string]: (lc: LC) => string} = {};

    private gameSubscription: SubscriptionLike | null = null;

    private gameLoading: boolean = true;
    private assetsLoading: boolean = false;
    private audioElements: {[name: string]: HTMLAudioElement} = {};
    private contactMail = CONST.CONTACT_MAIL;

    private async preloadImages() {
      const cards: Array<Card | null> = Deck.generateDeck();
      cards.push(null);
      const cardsImages = cards.map(card =>
        new Promise<void>((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve();
          img.onerror = reject;
          img.src = card ? card.makeAssetName() : `${CONST.IMAGE_FOLDER}/card_back.png`;
        })
      );
      await Promise.all(cardsImages);
    }

    private getSupportedAudioFormats() {
      const audio = document.createElement('audio');
      // ordered by ascending priority
      const queryFormats = [
        { extension: 'wav', type: 'audio/wav' },
        { extension: 'mp3', type: 'audio/mpeg' },
        { extension: 'ogg', type: 'audio/ogg' },
      ];
      const supportedFormats: string[] = [];
      queryFormats.forEach(format => {
        if (audio.canPlayType(format.type)) {
          supportedFormats.push(format.extension);
        }
      });
      return supportedFormats;
    }

    private chooseAudioFormatForFiles(supportedFormats: string[]) {
      const files: {[file: string]: string} = {};
      const unsupportedSet = new Set<string>();

      globals.AUDIO_FILES.forEach(file => {
        const i = file.lastIndexOf('.');
        if (i < 0) {
          console.warn(file + ' has no extension');
          return;
        }
        const extension = file.substring(i + 1);
        const fileName = file.substring(0, i);
        const extensionPrio = supportedFormats.indexOf(extension);
        if (extensionPrio < 0) {
          unsupportedSet.add(fileName);
          return;
        }

        const def = files[fileName];
        if (!def) {
          files[fileName] = extension;
        } else {
          const currentPrio = supportedFormats.indexOf(def);
          if (extensionPrio > currentPrio) {
            files[fileName] = extension;
          }
        }
      });

      unsupportedSet.forEach(file => {
        if (!files[file]) {
          console.warn('No supported format was found for ' + file);
        }
      });

      return files;
    }

    private async loadAudio() {
      const supportedFormats = this.getSupportedAudioFormats();
      const audioFiles = this.chooseAudioFormatForFiles(supportedFormats);

      const loadingFiles = Object.entries(audioFiles).map(([file, extension]) => {
        const audio = new Audio(CONST.AUDIO_FOLDER + '/' + file + '.' + extension);
        const audioPromise = new Promise<[string, HTMLAudioElement] | null>((resolve, reject) => {
          audio.oncanplaythrough = () => resolve([file, audio]);
          audio.onerror = (err) => reject(err);
        });
        const timeoutPromise = new Promise<typeof audioPromise>((_, reject) => {
          setTimeout(() => reject(new Error('audio loading timed out for ' + file)), 10000);
        });
        return Promise.race([audioPromise, timeoutPromise])
          .catch(err => {
            console.error('Failed to load audio file ' + file, err);
            return null;
          });
      });

      const loadedFiles = await Promise.all(loadingFiles);
      const audioEntries: {[name: string]: HTMLAudioElement} = {};
      loadedFiles
        .filter((x): x is [string, HTMLAudioElement] => !!x)
        .forEach(([file, audio]) => audioEntries[file] = audio);
      return audioEntries;
    }

    setLoading(isLoading: boolean) {
      this.gameLoading = !isLoading;
    }

    async goHome() {
      if (!!this.id) {
        await this.$router.push({ path: `/join-game/${this.id}` });
      } else {
        await this.$router.push('/');
      }
    }

    async created() {
      if (!this.sharedStore.userInteracted) {
        // if user didn't interact with the page yet audio won't work
        // return them to the landing page
        await this.goHome();
        return;
      }

      const response = await fetch(globals.SERVER_URL + 'is-logged-in', {
        credentials: 'include',
        method: 'GET'
      });

      if (!response.ok) {
        await this.goHome();
      } else {
        this.assetsLoading = true;
        try {
          const images = this.preloadImages();
          const audio = this.loadAudio();
          await images;
          this.audioElements = await audio;
          this.$subscribeTo(this.gameController.closed, (closed) => {
            this.$bvModal.show('reconnect-modal');
          });

          this.gameSubscription = this.gameController.start(this.id);
        } finally {
          this.assetsLoading = false;
        }
      }
    }

    destroyed() {
      if (this.gameSubscription && !this.gameSubscription.closed) {
        this.gameSubscription.unsubscribe();
      }
    }

    get currentProperties() {
      if (this.uiController.currentPhase === 'bidding') {
        return {
          availableBids: this.uiController.getState('bidding').availableBids,
        }
      } else if (this.uiController.currentPhase === 'exchanging') {
        return {
          exchangingState: this.uiController.getState('exchanging'),
        }
      } else if (this.uiController.currentPhase === 'figures') {
        return {
          figureStateHandler: this.uiController.getState('figures').figureStateHandler,
        }
      } else if (this.uiController.currentPhase === 'partner-calling') {
        return {
          partnerToCall: this.uiController.getState('partner-calling').partnerToCall
        }
      } else if (this.uiController.currentPhase === 'game-over') {
        return {
          scoreBoard: this.uiController.getState('game-over').scores
        }
      }
      return {};
    }

    get hasUiControl() {
      return Object.keys(components).indexOf(this.uiController.currentPhase) > -1;
    }

    handleBubbleAction(action: Line) {
      this.$set(this.bubbles, action.player, action.line);
    }

    loadingText(lc: LC): string {
      if (this.assetsLoading) {
        return lc.ui.loadingAssets;
      }
      if (this.gameLoading) {
        return lc.ui.loadingGame;
      }
      return '...';
    }

    private showBugReport() {
      const bugReporter = this.$refs['bug-reporter'] as BugReporter;
      bugReporter.show();
    }

    get shouldShowInviteScreen() {
      return this.gameLoading && this.sharedStore.isPrivateGameCreator;
    }

    get gameUrl() {
      return window.location.href;
    }

    private reconnect() {
      this.gameSubscription = this.gameController.start(this.id);
    }

    private goToLandingPage() {
      this.$router.push('/');
    }
  }
