
  import {Component, Inject, Vue} from 'vue-property-decorator';
  import {GameController} from '../control/gameController';
  import {mergeMap} from 'rxjs/operators';
  import {GameResponse} from '../model/gameResponse';
  import {LanguageConfig, LC} from '../language/languages';
  import {Figure} from '../data/figure';
  import {EMPTY, of} from 'rxjs';
  import {UserRepo} from '../control/userRepo';
  import TransparentPlayerActionWithId = GameResponse.TransparentPlayerActionWithId;

  export type Line = {
    isThisPlayer: boolean,
    announcement: boolean,
    player: string,
    line: (lc: LC) => string
  };

  @Component
  export default class ActionBox extends Vue {
    @Inject() gameController!: GameController;
    @Inject() lc!: LanguageConfig;
    @Inject() userRepo!: UserRepo;

    private lines: Line[] = [];
    private clearBeforeNext = false;

    created() {
      const lineStream = this.gameController.gameResponseStream.pipe(
        mergeMap(x => x),
        mergeMap(response => {
          if (this.clearBeforeNext) {
            this.lines.splice(0, this.lines.length);
            this.clearBeforeNext = false;
          }
          if (response.$type === 'TransparentPlayerActionWithId') {
            const line = this.mapActionToLine(response);
            return of(line);
          } else if (response.$type === 'Disconnected') {
            const line = lc => lc.ui['action-box'].disconnected;
            return of([{
              isThisPlayer: response.player === this.gameController.thisPlayer,
              player: response.player,
              line: line,
              announcement: false,
            }]);
          } else if (response.$type === 'Connected') {
            // TODO: this shouldn't be added here
            this.userRepo.addUser(response.playerId, response.thisPlayer.name);

            const line = lc => lc.ui['action-box'].connected;
            return of([{
              isThisPlayer: response.playerId === this.gameController.thisPlayer,
              player: response.playerId,
              line: line,
              announcement: false,
            }]);
          } else if (response.$type === 'OtherPlayerConnected') {
            // TODO: this shouldn't be added here
            this.userRepo.addUser(response.playerId, response.player.name);

            const line = lc => lc.ui['action-box'].connected;
            return of([{
              isThisPlayer: response.playerId === this.gameController.thisPlayer,
              player: response.playerId,
              line: line,
              announcement: false,
            }]);
          } else if (response.$type === 'OtherPlayerReConnected') {
            // TODO: this shouldn't be added here
            this.userRepo.addUser(response.playerId, response.player.name);

            const line = lc => lc.ui['action-box'].reconnected;
            return of([{
              isThisPlayer: response.playerId === this.gameController.thisPlayer,
              player: response.playerId,
              line: line,
              announcement: false,
            }]);
          } else if (response.$type === 'GameOver') {
            this.clearBeforeNext = true;
            return of([{
              isThisPlayer: false,
              player: '',
              line: lc => lc.ui['action-box'].gameOver,
              announcement: true,
            }]);
          }
          return EMPTY;
        }),
      );

      this.$subscribeTo(lineStream, lines => this.lines.push(...lines));
    }

    private mapActionToLine(action: TransparentPlayerActionWithId): Line[] {
      const makeLine = (line: (lc: LC) => string, player: string = action.player): Line => {
        return {
          isThisPlayer: player === this.gameController.thisPlayer,
          player: player,
          line: line,
          announcement: false,
        }
      };

      const figureText = (figures: GameResponse.Figure[]) => {
        return (lc: LC) => {
          const figs = figures.map(Figure.ofResponse).map(f => {
            if (f.kind === 'contra') {
              return `${lc.phase.figures.contra(f.contraCount)} ` +
                `${lc.phase.figures.figs[f.baseFigure.kind]}`;
            }
            return `${lc.phase.figures.figs[f.kind]}`;
          });
          const figuresLine = figs.join(', ');
          return figuresLine === '' ? lc.phase.figures.pass : figuresLine;
        };
      };

      const lines: Line[] = [];
      let line: (lc: LC) => string = _ => '';

      switch (action.action.$type) {
        case 'OtherExchanged':
          let numCards = action.action.numberOfCards;
          line = lc => lc.ui['action-box'].exchanged(numCards);
          lines.push(makeLine(line));
          break;
        case 'MeExchanged':
          numCards = action.action.cards.length;
          line = lc => lc.ui['action-box'].exchanged(numCards);
          lines.push(makeLine(line));
          break;
        case 'LastExchanged':
          Object.entries(action.action.exchangedTrumpCount).forEach(([playerId, trumpCount]) => {
            if (trumpCount > 0) {
              lines.push(makeLine(lc => lc.ui['action-box']['tarots-exchanged'](trumpCount), playerId));
            }
          });
          break;
        case 'Said':
          lines.push(makeLine(figureText(action.action.figure)));
          if (action.action.mandatoryContraPartyBy) {
            const fig = figureText([{$type: 'Contra', figure: {$type: 'Party'}}]);
            line = (lc: LC) => lc.phase.figures.mandatory + ' ' + fig(lc);
            lines.push(makeLine(line, action.action.mandatoryContraPartyBy));
          }
          break;
        case 'Bid':
          const a = action.action;
          line = lc => {
            let l = lc.ui['action-box'].bid(a.bid, a.hold);
            if (a.tryingForHonor) {
              l += ', ' + lc.ui['action-box']['trying-for-honor'];
            }
            return l;
          };
          lines.push(makeLine(line));
          break;
        case 'CalledFriend':
          const friend = action.action.friend;
          line = lc => lc.ui['action-box'].called(friend.rank);
          lines.push(makeLine(line));
          break;
        case 'Played':
          break;
      }

      lines.forEach(line => this.$emit('bubble-action', line));
      return lines;
    }


    updated() {
      this.scrollToBottom();
    }

    beforeUpdate() {
      const container = this.$el;
      if (!container) {
        return;
      }

      this.atScrollEnd = Math.abs(container.scrollHeight - container.scrollTop - container.clientHeight) < 30;
    }

    private atScrollEnd: boolean = true;
    private scrollToBottom() {
      if (!this.atScrollEnd) {
        return;
      }

      const container = this.$el;
      if (!container) {
        return;
      }
      container.scrollTop = container.scrollHeight;
    }
  }
