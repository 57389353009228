import {GameResponse} from '../model/gameResponse';
import isTrump = GameResponse.isTrump;
import isColor = GameResponse.isColor;
import {CONST} from "../const/const";

export enum Suite {
  Clubs = "club",
  Spades = "spade",
  Hearts = "heart",
  Diamonds = "diamond",
  Tarock = "tarock"
}

export class Card {
  private readonly _suite: Suite;
  private readonly _rank: number;

  static ofResponse(card: GameResponse.Card) {
    const colorRanks = {A: 1, J: 2, C: 3, Q: 4, K: 5};
    const suites = {Hearts: Suite.Hearts, Spades: Suite.Spades, Clubs: Suite.Clubs, Diamonds: Suite.Diamonds}
    if (isTrump(card)) {
      return new Card(Suite.Tarock, card.trump.rank);
    } else if (isColor(card)) {
      return new Card(suites[card.color.suite], colorRanks[card.color.rank]);
    } else {
      throw new Error('Card was neither color nor trump');
    }
  }

  toResponse(): GameResponse.Card {
    const colorRanks = {1: 'A', 2: 'J', 3: 'C', 4: 'Q', 5: 'K'};
    const mapSuite = (suite: Suite) => {
      switch (suite) {
        case Suite.Clubs:
          return 'Clubs';
        case Suite.Spades:
          return 'Spades';
        case Suite.Hearts:
          return 'Hearts';
        case Suite.Diamonds:
          return 'Diamonds';
        case Suite.Tarock:
          throw new Error('Tarock is not a suite');
      }
    };
    if (this.suite === Suite.Tarock) {
      return { trump: { rank: this.rank } };
    } else {
      return { color: { rank: colorRanks[this.rank], suite: mapSuite(this.suite)}}
    }
  }

  get suite(): Suite {
    return this._suite;
  }

  get rank(): number {
    return this._rank;
  }

  get key(): string {
    return `${this.suite}_${this.rank}`;
  }

  constructor(suite: Suite, rank: number) {
    this._suite = suite;
    this._rank = rank;
  }

  makeAssetName(): string {
    return `${CONST.IMAGE_FOLDER}/${this._suite}_${this._rank}.png`;
  }

  isHonor(): boolean {
    return this._suite === Suite.Tarock && [1, 21, 22].indexOf(this._rank) >= 0;
  }

  equals(that: Card | undefined) {
    return this._rank === that?._rank && this._suite === that?._suite;
  }
}
