import {Figure} from '../data/figure';
import {Scores} from '../data/gamestate';

export namespace GameResponse {
  export type Rank = 'A' | 'J' | 'C' | 'Q' | 'K'
  export type Suite = 'Hearts' | 'Spades' | 'Clubs' | 'Diamonds'

  export type TrumpRank = { rank: number }
  export type ColorRank = { rank: Rank, suite: Suite }

  export type Trump = { trump: TrumpRank }
  export type ColorCard = { color: ColorRank }
  export type Card =
    | ColorCard
    | Trump

  export function isTrump(card: Card): card is Trump {
    return (card as Trump).trump !== undefined;
  }

  export function isColor(card: Card): card is ColorCard {
    return (card as ColorCard).color !== undefined;
  }

  export type BiddingResult = {
    bid: number | null,
    lastBid: boolean,
    gameOver: boolean,
    tryForHonor: boolean,
    invitation: TrumpRank | null,
    hold: boolean,
  }

  export type EndBidding = {
    $type: 'EndBidding'
    callingPlayer: string,
    talonDistribution: { cards: Card[], drewCounts: { [playerId: string]: number } }
    exchangeResult: { cards: Card[], exchangeCount: number, canThrow: boolean },
  }

  export type Figure =
    | { $type: 'Trull' }
    | { $type: 'DoublePlay' }
    | { $type: 'Ulti' }
    | { $type: 'EightTrumps' }
    | { $type: 'NineTrumps' }
    | { $type: 'XXICatch' }
    | { $type: 'Volat' }
    | { $type: 'FourKings' }
    | { $type: 'Party' }
    | { $type: 'Contra', figure: Figure }

  export type Transition =
    | { $type: 'BiddingResult', results: BiddingResult[] }
    | EndBidding
    | { $type: 'FriendChosen', possibleFriends: { rank: number }[] }
    | { $type: 'FigureResult', hasToContraToCommit: boolean, mandatory: Figure[], possible: Figure[][] }
    | { $type: 'PlayableCards', playable: Card[] }

  export type TransparentPlayerAction =
    | { $type: 'OtherExchanged', numberOfCards: number }
    | { $type: 'MeExchanged', cards: Card[] }
    | { $type: 'LastExchanged', exchangedTrumpCount: { [playerId: string]: number },
        playerExchangedTrumps: TrumpRank[] }
    | { $type: 'Played', card: Card, takes: string | null }
    | { $type: 'Said', figure: Figure[], mandatoryContraPartyBy: string | null }
    | { $type: 'Bid', bid: number | null, tryingForHonor: boolean, hold: boolean }
    | { $type: 'Throw' }
    | { $type: 'CalledFriend', friend: TrumpRank }

  export type TransparentPlayerActionWithId =
    { $type: 'TransparentPlayerActionWithId', player: string, action: TransparentPlayerAction, seqNo: number }

  export type UserDesc = {
    name: string
  }

  export type Team =
    | { $type: 'PlayerTeam' }
    | { $type: 'OpponentTeam' }

  export type Scores = {
    player: string,
    partner: string | null,
    opponents: string[],
    partyValue: 3 | 2 | 1 | 0,
    scoreTable: {
      team: Team,
      figure: Figure,
      silent: boolean,
      scores : {
        [playerId: string]: number,
      }
    }[]
  };

  export type GameResponse =
    | { $type: 'Connected', playerId: string, thisPlayer: UserDesc }
    | { $type: 'Reconnected', playerId: string }
    | { $type: 'Disconnected', player: string }
    | {
        $type: 'GameStarted', firstPlayer: string, dealer: string, cards: Card[],
        positions: { player: string, position: number }[], playerDescriptions: { [playerId: string]: UserDesc },
        seqNo: number
      }
    | { $type: 'GameStep', transition: Transition, seqNo: number }
    | TransparentPlayerActionWithId
    | { $type: 'GameOver', scores: Scores | null, seqNo: number }
    | { $type: 'OtherPlayerConnected', playerId: string, player: UserDesc }
    | { $type: 'OtherPlayerReConnected', playerId: string, player: UserDesc }
    | { $type: 'Pong' }
    | { $type: 'ReplayDone' }
}
