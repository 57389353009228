import {Player} from './player';
import {Card} from "./card";
import {GameResponse} from '../model/gameResponse';
import {Figure} from './figure';
import BiddingResult = GameResponse.BiddingResult;

export type PlayerPosition = "right" | "top" | "left" | "bottom";

export interface StateData {
  readonly positions: { [K in PlayerPosition]: string }
  readonly players: Player[];
  readonly hand: Card[];
  readonly handCounts: { [key: string]: number };
  currentPlayer: string;
  myTeam: "enemy" | "friend" | "unknown";
  readonly dealer: string;
  exchanged: { [player: string]: { exchanged: number, trumpCount: number } };
  playerExchanged: Array<Card | null>;
  callingPlayer: string | undefined;
  cardsOnTable: { [player: string]: Card };
}

export interface PreGame {
  readonly kind: "pre-game";
  readonly thisPlayer: string;
}

export interface Bidding {
  readonly kind: "bidding";
  readonly bids: (number | null)[];
  availableBids: BiddingResult[] | null;
}

export interface Exchanging {
  readonly kind: "exchanging";
  readonly player: string;
  exchangeable: Card[];
  readonly canThrow: boolean;
}

export interface PartnerCalling {
  readonly kind: "partner-calling";
  readonly validPartners: Card[];
}

export interface Figures {
  readonly kind: "figures";
  readonly figures: Figure[][];
  readonly mandatory: Figure[];
  readonly possible: Figure[][];
  readonly hastoContra: boolean;
}

export interface Main {
  readonly kind: "main";
  readonly playable: Card[];
  readonly takes: string | null;
}

export type Scores = {
  player: string,
  partner: string | null,
  opponents: string[],
  partyValue: 3 | 2 | 1 | 0,
  scoreTable: {
    team: 'player' | 'opponent',
    figure: Figure,
    silent: boolean,
    scores : {
      [playerId: string]: number,
    }
  }[]
};

export namespace Scores {
  export function ofResponse(scores: GameResponse.Scores): Scores {
    return {
      player: scores.player,
      partner: scores.partner,
      opponents: scores.opponents,
      partyValue: scores.partyValue,
      scoreTable: scores.scoreTable.map(st => {
        return {
          team: st.team.$type === 'PlayerTeam' ? 'player' : 'opponent',
          figure: Figure.ofResponse(st.figure),
          silent: st.silent,
          scores: { ...st.scores },
        }
      })
    }
  }
}

export interface GameOver {
  readonly kind: 'game-over';
  readonly scores: Scores | null,
}

export type Phase = 
  | PreGame
  | Bidding
  | Exchanging
  | PartnerCalling
  | Figures
  | Main
  | GameOver
  | { kind: 'waiting' }

export interface GameState<P extends Phase> {
  phase: P;
  state: StateDataType<P>;
}

type StateDataType<P extends Phase> = P['kind'] extends 'pre-game' | 'waiting' ? undefined : StateData;