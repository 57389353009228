export const globals = {
  SERVER_URL: process.env.SERVER_URL,
  WS_URL: makeWssUrl(process.env.SERVER_URL || ''),
  AUDIO_FILES: process.env.AUDIO_FILES as unknown as string[],
};

function makeWssUrl(envUrl: string) {
  let uri: URL;

  if (!envUrl.startsWith('http') && !envUrl.startsWith('//')) {
    uri = new URL('', window.location.href);
    uri.hash = '';
    uri.pathname = envUrl;
  } else {
    uri = new URL('', envUrl);
  }

  uri.protocol = uri.protocol.replace(/^http/, 'ws');
  return uri.href;
}