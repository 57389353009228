
  import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
  import {Card} from '../../data/card';
  import CardComponent from './CardComponent.vue';

  export type CardState = {
    card: Card | null,
    stick: boolean,
    hover: boolean,
  }

  @Component({
    components: {
      CardComponent
    }
  })
  export default class Hand extends Vue {
    @Prop({required: true}) cardsInHand!: Array<Card | null>;
    @Prop({default: function () { return []; }}) activeCards!: Card[];
    @Prop({default: false}) interactive!: boolean;
    cards: CardState[] = [];
    minBoxWidth: number = 0;
    minBoxHeight: number = 0;
    cardMargin: number = 0;

    private cardSize: {width: number, height: number} = {width: 0, height: 0};

    created() {
      this.updateCards([], this.cardsInHand);
    }

    @Watch('cardsInHand')
    updateCards(oldValue: Array<Card | null>, newValue: Array<Card | null>) {
      const oldState: { [key: string]: CardState } = {};
      this.cards.forEach((card, index) => {
        oldState[card.card?.key ?? index] = this.cards[index];
      });

      const newState: CardState[] = newValue.map((c, index) => {
        return oldState[c?.key ?? index] ?? {card: c, stick: false, hover: false};
      });

      this.cards.splice(0, this.cards.length, ...newState);
      this.cardMargin = this.calculateMargin(this.cardSize);
    }

    shouldLift(cardState: CardState) {
      if (!this.interactive) {
        return false;
      }

      if (cardState.stick) {
        return true;
      }

      if (!cardState.card || !this.isLiftActive(cardState.card)) {
        return false;
      }

      return cardState.hover;
    }

    lift(cardState: CardState) {
      cardState.hover = true;
    }

    unlift(cardState: CardState) {
      cardState.hover = false;
    }

    isLiftActive(card: Card): boolean {
      return this.activeCards.findIndex(c => c.equals(card)) > -1;
    }

    private sizeChanged(size: {width: number, height: number}, index: number) {
      if (index > 0) {
        return;
      }

      this.minBoxHeight = size.height;
      this.cardMargin = this.calculateMargin(size);
      this.minBoxWidth = (size.width + this.cardMargin) * 8 + size.width;
      this.cardSize = size;
    }

    private calculateMargin(size: {width: number, height: number}) {
      if (!this.interactive) {
        return -size.width * 2/3;
      }

      const defaultMargin = -size.width * 2/7;
      const y = this.cards.length - 1;
      const calculated = defaultMargin * (7 * y - 40) / (2 * y);
      return Math.min(0, Math.max(calculated, defaultMargin));
    }

    private distributeMarginLeft(states: CardState[], index: number) {
      if (!this.interactive) {
        return index == 0 ? 0 : this.cardMargin;
      }

      const anyHover = states.slice(0, states.length - 1).some(x => x?.hover);
      const adjusted = anyHover && states.length > 2 ? this.cardMargin * (states.length - 1) / (states.length - 2) : this.cardMargin;

      if (index > 0 && states[index - 1]?.hover) {
        return 0;
      }
      return index > 0 ? adjusted : 0;
    }

    get cardsSorted() {
      return this.sortedCards(this.cards);
    }

    private sortedCards(cards: CardState[]) {
      const suiteOrder = this.calculateSuiteOrder(cards);

      return [...cards].sort((a, b) => {
        if (a.card === null  && b.card === null) {
          return 0;
        }
        if (a.card === null) {
          return 1;
        }
        if (b.card === null) {
          return -1;
        }
        return (suiteOrder[a.card.suite] * 100 - suiteOrder[b.card.suite] * 100) + (a.card.rank - b.card.rank);
      });

    }

    private calculateSuiteOrder(cards: CardState[]) {
      const reds = new Set<string>();
      const blacks = new Set<string>();
      cards.forEach(card => {
        if (card.card == null) {
          return;
        }
        if (card.card.suite === 'diamond' || card.card.suite === 'heart') {
          reds.add(card.card.suite);
        } else if (card.card.suite === 'club' || card.card.suite === 'spade') {
          blacks.add(card.card.suite);
        }
      });
      const redArr = Array.from(reds);
      const blackArr = Array.from(blacks);
      const colorOrder: string[] = [];
      let pickForm = blackArr.length > redArr.length ? 'black' : 'red';
      let i = 0;
      let j = 0;
      while (i < redArr.length || j < blackArr.length) {
        if (pickForm === 'red') {
          colorOrder.push(redArr[i]);
          if (j < blackArr.length) {
            pickForm = 'black'
          }
          i++;
        } else {
          colorOrder.push(blackArr[j]);
          if (i < redArr.length) {
            pickForm = 'red'
          }
          j++;
        }
      }
      const suiteOrder = {tarock: 4};
      colorOrder.forEach((suite, index) => suiteOrder[suite] = index);
      return suiteOrder;
    }
  }
