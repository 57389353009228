import {Observable, Subject, SubscriptionLike} from 'rxjs';
import {webSocket, WebSocketSubjectConfig} from 'rxjs/webSocket';

export class TypedWebsocket<Send, Receive> {
  private readonly wsSubject: Subject<Send | Receive>;

  constructor(urlConfigOrSource: string | WebSocketSubjectConfig<Send | Receive>) {
    this.wsSubject = webSocket(urlConfigOrSource);
  }

  asObservable(): Observable<Receive> {
    return this.wsSubject.asObservable() as Observable<Receive>;
  }

  next(value: Send) {
    this.wsSubject.next(value);
  }

  get closable(): SubscriptionLike {
    return this.wsSubject;
  }
}
