import {GameResponse} from '../model/gameResponse';
import {CONST} from '../const/const';
import {assertNever} from '../utils/utils';

export type Figure =
  | { kind: 'trull' }
  | { kind: 'double-play' }
  | { kind: 'four-kings' }
  | { kind: 'ulti' }
  | { kind: 'eight-trumps' }
  | { kind: 'nine-trumps' }
  | { kind: 'xxi-catch' }
  | { kind: 'volat' }
  | { kind: 'party' }
  | { kind: 'contra', baseFigure: Figure, contraCount: number }

export namespace Figure {
  export const allFigures: Figure['kind'][] = [
    'trull', 'double-play', 'four-kings', 'ulti', 'eight-trumps',
    'nine-trumps', 'xxi-catch', 'volat', 'party',
  ];

  export function ofResponse(resp: GameResponse.Figure): Figure {
    switch (resp.$type) {
      case 'Trull':
        return {kind: 'trull'};
      case 'DoublePlay':
        return {kind: 'double-play'};
      case 'Ulti':
        return {kind: 'ulti'};
      case 'EightTrumps':
        return {kind: 'eight-trumps'};
      case 'NineTrumps':
        return {kind: 'nine-trumps'};
      case 'XXICatch':
        return {kind: 'xxi-catch'};
      case 'Volat':
        return {kind: 'volat'};
      case 'FourKings':
        return {kind: 'four-kings'};
      case 'Party':
        return {kind: 'party'};
      case 'Contra':
        const base = getFigureWithDepth(resp);
        if (base === null) {
          throw new Error('Could not compute base figure');
        }
        return {kind: 'contra', baseFigure: base.baseFigure, contraCount: base.depth};
      default:
        return assertNever(resp);
    }
  }

  export function toResponse(figure: Figure): GameResponse.Figure {
    switch (figure.kind) {
      case 'trull':
        return {$type: 'Trull'};
      case 'double-play':
        return {$type: 'DoublePlay'};
      case 'four-kings':
        return {$type: 'FourKings'};
      case 'ulti':
        return {$type: 'Ulti'};
      case 'eight-trumps':
        return {$type: 'EightTrumps'};
      case 'nine-trumps':
        return {$type: 'NineTrumps'};
      case 'xxi-catch':
        return {$type: 'XXICatch'};
      case 'volat':
        return {$type: 'Volat'};
      case 'party':
        return {$type: 'Party'};
      case 'contra':
        return fromFigureWithDepth(figure);
      default:
        return assertNever(figure);
    }
  }

  export function getFigureWithDepth(figure: GameResponse.Figure): { baseFigure: Figure, depth: number } | null {
    if (figure.$type === 'Contra') {
      const base = getFigureWithDepth(figure.figure);
      if (base === null) {
        return null;
      }
      if (base.depth === CONST.MAX_CONTRA_DEPTH) {
        return null;
      }
      return {baseFigure: base.baseFigure, depth: base.depth + 1};
    }
    return {baseFigure: ofResponse(figure), depth: 0};
  }

  function fromFigureWithDepth(figure: { kind: 'contra', baseFigure: Figure, contraCount: number }): GameResponse.Figure {
    let base = toResponse(figure.baseFigure);
    for (let i = 0; i < figure.contraCount; i++) {
      base = {$type: 'Contra', figure: base}
    }
    return base;
  }

  export function figureId(figure: Figure) {
    if (figure.kind === 'contra') {
      return `${figure.kind}${figure.baseFigure.kind}${figure.contraCount}`;
    }
    return figure.kind;
  }
}
