import {GameController} from './gameController';
import {Card, Suite} from '../data/card';
import {Bidding, Exchanging, GameState, Phase, Scores} from '../data/gamestate';
import {Observable, of, Subject, Subscription, SubscriptionLike} from 'rxjs';
import {concatMap, delay, mergeMap, repeat, share, tap} from 'rxjs/operators';
import {GameResponse} from '../model/gameResponse';
import {TypedWebsocket} from './typedWebsocket';
import {GameRequest} from '../model/gameRequest';
import {assertNever, getOrThrow, nullArray} from '../utils/utils';
import {Figure} from '../data/figure';
import {globals} from '../../../env';
import {UserRepo} from './userRepo';
import {WebSocketSubjectConfig} from "rxjs/webSocket";
import {CONST} from "../const/const";

type Delay = { delayObservable: Observable<any> }

function isDelay(x: any): x is Delay {
  return (x as Delay).delayObservable !== undefined;
}

export class RealController implements GameController {
  private gameConnection: TypedWebsocket<GameRequest.PlayerCommand, GameResponse.GameResponse> | undefined;
  private gameState: GameState<Phase> | undefined;
  private _thisPlayer: string | undefined;
  private readonly gameUpdateEmitter: Subject<GameState<Phase>> = new Subject<GameState<Phase>>();
  readonly gameUpdated: Observable<GameState<Phase>> = this.gameUpdateEmitter.asObservable();
  private _gameResponseStream: Subject<Observable<GameResponse.GameResponse>> =
    new Subject<Observable<GameResponse.GameResponse>>();
  private isClosed: boolean = false;

  // monotonic sequence number of server sent events
  // Only monotonic at the same table.
  private seqNo: number = -1;

  private _isReplaying: boolean = false;
  private currentSubscriptions: Subscription[] = [];
  private readonly onClose: Subject<CloseEvent> = new Subject();

  constructor(private readonly userRepo: UserRepo) {
    window['realController'] = this;
  }

  private assignState<P extends Phase>(state: GameState<P>) {
    this.gameState = state;
  }

  private handleMessage(message: GameResponse.GameResponse) {
    console.log(message);
    switch (message.$type) {
      case 'Connected':
        this._thisPlayer = message.playerId;
        // reconnect also
        if (!this.gameState) {
          this.assignState({
            phase: {
              kind: 'pre-game',
              thisPlayer: message.playerId,
            },
            state: undefined,
          });
          this.gameUpdateEmitter.next(this.gameState);
        }
        break;
      case 'Disconnected':
        this.assignState({
          phase: {
            kind: 'waiting',
          },
          state: undefined,
        });
        this.gameUpdateEmitter.next(this.gameState);
        break;
      case 'GameStarted':
        this.seqNo = message.seqNo;
        this.assignState({
          phase: {kind: 'pre-game', thisPlayer: getOrThrow(this.thisPlayer, 'thisPlayer')},
          state: undefined,
        });
        this.gameUpdateEmitter.next(this.gameState);

        const positions = {};
        message.positions.forEach(pos => {
          positions[pos.position] = pos.player;
        });
        Object.entries(message.playerDescriptions).forEach(([id, desc]) => {
          this.userRepo.addUser(id, desc.name);
        });
        const posOffset = message.positions.find(pos => pos.player === this._thisPlayer)?.position ?? -1;
        const makePos = idx => (posOffset + idx) % message.positions.length;
        const handCounts: { [p: string]: number } = {};
        message.positions.forEach(pos => handCounts[pos.player] = 9);

        this.gameState = {
          phase: {
            kind: "bidding",
            bids: [],
            availableBids: null,
          },
          state: {
            positions: {
              right: positions[makePos(1)],
              top: positions[makePos(2)],
              left: positions[makePos(3)],
              bottom: positions[makePos(0)],
            },
            players: message.positions.map(pos => {
              return {name: pos.player};
            }),
            handCounts: handCounts,
            currentPlayer: message.firstPlayer,
            myTeam: 'unknown',
            hand: message.cards.map(Card.ofResponse),
            dealer: message.dealer,
            exchanged: {},
            playerExchanged: [],
            cardsOnTable: {},
            callingPlayer: undefined,
          },
        };
        this.gameUpdateEmitter.next(this.gameState);
        break;
      case 'GameStep':
        this.seqNo = message.seqNo;
        if (this.gameState === undefined) {
          throw new Error('gameState cannot be undefined at this point');
        }
        if (this._thisPlayer === undefined) {
          throw new Error('thisPlayer cannot be undefined at this point');
        }

        switch (message.transition.$type) {
          case 'BiddingResult':
            const bidding = this.gameState.phase as Bidding;
            bidding.availableBids = message.transition.results;
            this.gameState.state.currentPlayer = this._thisPlayer;
            this.gameUpdateEmitter.next(this.gameState);
            break;
          case 'EndBidding':
            const distrib = message.transition.talonDistribution;

            const state: GameState<Exchanging> = {
              phase: {
                kind: 'exchanging',
                player: message.transition.callingPlayer,
                exchangeable: message.transition.exchangeResult.cards.map(Card.ofResponse),
                canThrow: message.transition.exchangeResult.canThrow,
              },
              state: this.gameState.state,
            };

            state.state.callingPlayer = message.transition.callingPlayer;
            state.state.exchanged = {};
            state.state.hand.push(...message.transition.talonDistribution.cards.map(Card.ofResponse));
            state.state.players.forEach(player => {
              state.state.handCounts[player.name] += distrib.drewCounts[player.name];
            });
            this.gameState = state;
            this.gameUpdateEmitter.next(this.gameState);
            break;
          case 'FriendChosen':
            const trumps = message.transition.possibleFriends.map(rank => new Card(Suite.Tarock, rank.rank));
            this.gameState.phase = {
              kind: 'partner-calling',
              validPartners: trumps,
            };
            this.gameUpdateEmitter.next(this.gameState);
            break;
          case 'FigureResult':
            this.gameState.phase = {
              kind: 'figures',
              figures: [],
              hastoContra: message.transition.hasToContraToCommit,
              mandatory: message.transition.mandatory.map(Figure.ofResponse),
              possible: message.transition.possible.map(x => x.map(Figure.ofResponse)),
            };
            this.gameUpdateEmitter.next(this.gameState);
            break;
          case 'PlayableCards':
            this.gameState.phase = {
              kind: 'main',
              playable: message.transition.playable.map(Card.ofResponse),
              takes: null,
            };
            this.gameUpdateEmitter.next(this.gameState);
            break;
          default:
            assertNever(message.transition);
        }
        break;
      case 'TransparentPlayerActionWithId':
        this.seqNo = message.seqNo;
        if (this.gameState === undefined) {
          throw new Error('gameState cannot be undefined at this point');
        }

        switch (message.action.$type) {
          case 'LastExchanged':
            const trumps: Array<Card | null> = message.action.playerExchangedTrumps.map(r => new Card(Suite.Tarock, r.rank));
            const callingPlayer = getOrThrow(this.gameState.state.callingPlayer, 'callingPlayer');
            const playerExchangeCount = getOrThrow(this.gameState.state.exchanged[callingPlayer].exchanged,
              'exchanged[callingPlayer].exchanged');
            for (let i = trumps.length; i < playerExchangeCount; i++) {
              trumps.push(null);
            }
            Object.entries(message.action.exchangedTrumpCount).forEach(([playerId, count]) => {
              this.gameState!.state.exchanged[playerId].trumpCount = count;
            });

            this.gameState.state.playerExchanged = trumps;
            this.gameUpdateEmitter.next(this.gameState);
            break;
          case 'OtherExchanged':
            if (message.player === this.gameState.state.callingPlayer) {
              this.gameState.state.playerExchanged = nullArray(message.action.numberOfCards);
            }
            this.gameState.state.exchanged[message.player] = {
              exchanged: message.action.numberOfCards,
              trumpCount: 0,
            };
            this.gameState.state.handCounts[message.player] -= message.action.numberOfCards;
            this.gameUpdateEmitter.next(this.gameState);
            break;
          case 'MeExchanged':
            for (let i = this.gameState.state.hand.length - 1; i >= 0; --i) {
              if (message.action.cards.findIndex(c =>
                  Card.ofResponse(c).equals(this.gameState!.state.hand[i])) >= 0
              ) {
                this.gameState.state.hand.splice(i, 1);
              }
            }
            this.gameState.state.exchanged[message.player] = {
              exchanged: message.action.cards.length,
              trumpCount: 0
            };
            this.gameState.state.handCounts[message.player] -= message.action.cards.length;
            this.gameUpdateEmitter.next(this.gameState);
            break;
          case 'Played':
            this.gameState.phase = {
              kind: 'main',
              playable: [],
              takes: message.action.takes,
            };
            if (Object.keys(this.gameState.state.cardsOnTable).length === 4) {
              this.gameState.state.cardsOnTable = {};
            }
            this.gameState.state.cardsOnTable[message.player] = Card.ofResponse(message.action.card);
            this.gameState.state.handCounts[message.player] -= 1;
            this.gameUpdateEmitter.next(this.gameState);
        }
        break;
      case 'GameOver':
        this.seqNo = message.seqNo;
        if (!this.gameState) {
          throw new Error('gameState cannot be undefined at this point');
        }

        // TODO: backend should send this
        this.gameState.phase = {
          kind: 'game-over',
          scores: message.scores === null ?  null : Scores.ofResponse(message.scores),
        };
        this.gameUpdateEmitter.next(this.gameState);
        break;
      case 'Reconnected':
        this.gameConnection?.next({ $type: 'ReplayState', fromSeqNo: this.seqNo });
        this._isReplaying = true;
        break;
      case 'ReplayDone':
        this._isReplaying = false;
        break;
    }
  }

  bid(bid: number | null): void {
    this.gameConnection?.next({
      $type: 'GameAction', action: {
        $type: 'Bid',
        bid: bid,
      }
    });
  }

  exchange(cards: Card[]): void {
    this.gameConnection?.next({
      $type: 'GameAction', action: {
        $type: 'Exchange',
        cards: cards.map(card => card.toResponse()),
      }
    });
  }

  callPartner(tarockNumber: number): void {
    this.gameConnection?.next({
      $type: 'GameAction', action: {
        $type: 'CallFriend',
        friend: {rank: tarockNumber},
      }
    });
  }

  sayFigures(figures: Figure[]): void {
    this.gameConnection?.next({
      $type: 'GameAction', action: {
        $type: 'Say',
        figures: figures.map(Figure.toResponse),
      }
    });
  }

  playCard(card: Card): void {
    this.gameConnection?.next({
      $type: 'GameAction', action: {
        $type: 'Play',
        card: card.toResponse(),
      }
    });
  }

  throw(): void {
    this.gameConnection?.next({
      $type: 'GameAction', action: {
        $type: 'Throw'
      }
    });
  }

  leave(): void {
    this.gameConnection?.next({ $type: 'Leave' })
  }

  start(id: string | undefined): SubscriptionLike {
    const url = !!id ? `game/join-table?table-id=${id}` : 'game/ws';

    const conf: WebSocketSubjectConfig<GameRequest.PlayerCommand | GameResponse.GameResponse> = {
      url: globals.WS_URL + url,
      openObserver: {
        next: (openEvent) => {
          this.isClosed = false;
        }
      },
      closeObserver: {
        next: (closeEvent) => {
          console.warn('Socket was closed', closeEvent);
          this.isClosed = true;
          this.onClose.next(closeEvent);
        }
      }
    }

    // TODO: error handling when id is wrong or otherwise can't connect
    this.gameConnection = new TypedWebsocket<GameRequest.PlayerCommand, GameResponse.GameResponse>(conf);
    const stream =
      this.gameConnection.asObservable().pipe(
        concatMap(x => {
          if (this.delay) {
            const ret = this.delay.delayObservable.pipe(mergeMap(y => of(x)));
            this.delay = undefined;
            return ret;
          }
          return of(x);
        }),
        share(),
      );
    this.currentSubscriptions.push(
        stream.subscribe(msg => this.handleMessage(msg))
    );
    this._gameResponseStream.next(stream);
    this.currentSubscriptions.push(this.doPingPong().subscribe());

    const self = this;
    return {
      get closed(): boolean {
        return !!self.gameConnection?.closable.closed;
      },
      unsubscribe: () => {
        this.currentSubscriptions.forEach(sub => sub.unsubscribe());
        this.gameConnection?.closable.unsubscribe();
      }
    };
  }

  private delay: Delay | undefined = undefined;

  private doPingPong() {
    return of(null)
        .pipe(
            delay(Math.random() * CONST.PING_PONT_JITTER_MS + CONST.PING_PONG_INTERVAL_MS),
            tap(() => {
              if (!this.isClosed) {
                this.gameConnection?.next({$type: 'Ping'});
              }
            }),
            repeat()
        )
  }

  delayBy(millis: number) {
    const time = Date.now() + millis;
    this.delayUntilComplete(of(null).pipe(delay(new Date(time))));
  }

  delayUntilComplete(observable: Observable<any>) {
    this.delay = {delayObservable: observable};
  }

  get gameResponseStream(): Observable<Observable<GameResponse.GameResponse>> {
    return this._gameResponseStream.asObservable();
  }

  get thisPlayer(): string | undefined {
    return this._thisPlayer;
  }

  get isReplaying(): boolean {
    return this._isReplaying;
  }

  get closed(): Observable<CloseEvent> {
    return this.onClose.asObservable();
  }


}
