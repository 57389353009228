export class UserRepo {
  private readonly userIdToUserName: {[userId: string]: string} = {};

  addUser(userId: string, userName: string) {
    this.userIdToUserName[userId] = userName;
  }

  getUserName(userId: string): string | undefined {
    return this.userIdToUserName[userId];
  }
}