
  import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
  import Hand from './Hand.vue';

  @Component({
    components: {
      Hand
    }
  })
  export default class EnemyHand extends Vue {
    @Prop({required: true}) numberOfCards!: number;
    cards: null[] = [];

    created() {
      this.updateCards(0, this.numberOfCards);
    }

    @Watch('numberOfCards')
    updateCards(oldValue: number, newValue: number) {
      const nulls: null[] = [];
      for (let i = 0; i < newValue; i++) {
        nulls.push(null);
      }
      this.cards.splice(0, nulls.length, ...nulls);
    }
  }
