import {Figure} from "../data/figure";
import {Figures} from "../data/gamestate";
import figureId = Figure.figureId;

export class FigureStateHandler {
    // needed for Vue change tracking;
    private selectionChanged: number = 0;
    private readonly selectedFigures = new Map<string, Figure>();
    private readonly figureState = new Map<string, FigureState>();
    private _figures: Figures = {
        kind: 'figures',
        possible: [],
        mandatory: [],
        figures: [],
        hastoContra: false
    };

    set figures(figures: Figures) {
        this.selectedFigures.clear();
        this.figureState.clear();
        this._figures = figures;
        figures.mandatory.forEach(fig => this.figureState.set(figureId(fig),
          {selected: false, active: true, mandatory: true}));
        ([] as Figure[]).concat(...figures.possible).forEach(fig =>
          this.figureState.set(figureId(fig), {selected: false, active: true, mandatory: false}));
    }

    get figures() {
        return this._figures;
    }

    get allFigures() {
        return this.figures.mandatory.concat(...this.figures.possible);
    }

    isMandatoryCorrect(): boolean {
        return this._figures.mandatory.every(f =>
            this.selectedFigures.has(figureId(f)));
    }

    isPossibleCorrect(): boolean {
        const possibleKeySet = new Set<string>(this.selectedFigures.keys());
        this._figures.mandatory.forEach(m => possibleKeySet.delete(figureId(m)));

        const didntSayAnything = this.selectedFigures.size === 0;
        const didSayContra = Array.from(this.selectedFigures.values()).some(x => x.kind === 'contra');
        // hasToContra implies (didntSayAnything || didSayContra)
        const contra = !this._figures.hastoContra || (didntSayAnything || didSayContra);

        return contra && Array.from(possibleKeySet.keys()).every(figKey => {
            const setsContainingFig = this._figures.possible.filter(figs =>
                figs.findIndex(f => figureId(f) === figKey) > -1);
            return setsContainingFig.some(set => {
                const containsAll = set.every(f => this.selectedFigures.has(figureId(f)));
                const containsNone = set.every(f => !this.selectedFigures.has(figureId(f)));
                return containsAll || containsNone;
            });
        });
    }

    isPassable(): boolean {
        return this.isMandatoryCorrect() && this.isPossibleCorrect();
    }

    getSelectedFigures(): Figure[] {
        return Array.from(this.selectedFigures.values());
    }

    select(figure: Figure) {
        let id = figureId(figure);
        this.selectedFigures.set(id, figure);
        const f = this.figureState.get(id);
        if (f) {
            f.selected = true;
        }
        this.selectionChanged += 1;
    }

    unselect(figure: Figure) {
        let id = figureId(figure);
        this.selectedFigures.delete(id);
        const f = this.figureState.get(id);
        if (f) {
            f.selected = false;
        }
        this.selectionChanged += 1;
    }

    isSelected(figure: Figure) {
        let id = figureId(figure);
        return this.figureState.get(id)?.selected ?? false;
    }

    isSelectable(figure: Figure) {
        return this.figureState.has(figureId(figure));
    }

    isMandatory(figure: Figure) {
        return this.figureState.get(figureId(figure))?.mandatory ?? false;
    }
}

type FigureState = {
    active: boolean,
    selected: boolean,
    mandatory: boolean
}