
  import {Component, Inject, Prop, Vue, Watch} from "vue-property-decorator";
  import {Figure} from "../data/figure";
  import {LanguageConfig, LC} from "../language/languages";
  import {GameController} from "../control/gameController";
  import {FigureStateHandler} from '../control/figureStateHandler';
  import UiButton from './Button.vue';

  type ButtonState = {
    text: (lc: LC) => string,
    active: boolean,
    highlighted: boolean,
    figure: Figure | null
  }

  @Component({
    components: {
      UiButton
    }
  })
  export default class ExchangeButtons extends Vue {
    @Inject() readonly lc!: LanguageConfig;
    @Inject() readonly gameController!: GameController;
    @Prop({required: true}) figureStateHandler!: FigureStateHandler;

    passActive: boolean = this.figureStateHandler.isPassable();

    private textForFigure(figure: Figure) {
      return (lc: LC) => {
        if (figure.kind === 'contra') {
          const contraText = lc.phase.figures.contra(figure.contraCount);
          return `${contraText} ` + lc.phase.figures.figs[figure.baseFigure.kind];
        }
        return lc.phase.figures.figs[figure.kind];
      }
    }

    buttonStates: {[key: string]: ButtonState} = this.initialButtonStates();

    initialButtonStates() {
      const states: {[key: string]: ButtonState} = {};
      Figure.allFigures.forEach(fig => {
        states[fig] = {
          text: lc => lc.phase.figures.figs[fig],
          active: false,
          highlighted: false,
          figure: null
        };
      });

      this.changeButtonState(states);
      return states;
    }

    changeButtonState(btnState: {[key: string]: ButtonState}): void {
      this.figureStateHandler.allFigures.forEach(fig => {
        const key = fig.kind === 'contra' ? fig.baseFigure.kind : fig.kind;
        btnState[key].text = this.textForFigure(fig);
        btnState[key].figure = fig;
        btnState[key].active = this.figureStateHandler.isSelectable(fig);
        btnState[key].highlighted = this.figureStateHandler.isSelected(fig);
      });
    }

    @Watch('figureStateHandler', {deep: true})
    watchFigureStateHandler() {
      this.changeButtonState(this.buttonStates);
      this.passActive = this.figureStateHandler.isPassable();
    }

    toggleSelection(buttonState: ButtonState) {
      if (!buttonState.figure) {
        return;
      }

      if (!buttonState.highlighted) {
        this.figureStateHandler.select(buttonState.figure)
      } else {
        this.figureStateHandler.unselect(buttonState.figure)
      }
    }

    pass() {
      this.gameController.sayFigures(this.figureStateHandler.getSelectedFigures());
      Object.values(this.buttonStates).forEach(btn => {
        btn.active = false;
      });
      this.passActive = false;
    }
  }
