
  import UiButton from './Button.vue';
  import {Component, Inject, Prop, Vue, Watch} from "vue-property-decorator";
  import {LanguageConfig, LC} from "../language/languages";
  import {GameController} from '../control/gameController';
  import {GameResponse} from '../model/gameResponse';
  import BiddingResult = GameResponse.BiddingResult;

  type ButtonState = {
    text: (lc: LC) => string,
    bid: BiddingResult,
    active: boolean
  }

  @Component({
    components: {
      UiButton
    }
  })
  export default class Bidding extends Vue {
    @Inject() readonly lc!: LanguageConfig;
    @Inject() readonly gameController!: GameController;
    @Prop({required: true}) availableBids!: Array<BiddingResult>;

    private buttonState: ButtonState[] = [];

    @Watch('lc', {deep: true})
    private resetLanguage() {
      this.selectActiveBids();
    }

    @Watch('availableBids')
    private selectActiveBids() {
      this.buttonState.forEach((state, index) => {
        const bidIndex = index === 4 ? null : 3 - index;
        const availableBid = this.availableBids.find(b => b.bid === bidIndex);
        const text = (lc: LC) => {
          const bidNumText = lc.phase.bidding.bid(bidIndex);
          return availableBid?.hold ? lc.phase.bidding.hold(bidIndex ?? -1) : bidNumText;
        };
        if (availableBid) {
          state.active = true;
        }
        state.text = text;
      });
    }

    created() {
      this.buttonState = this.makeInitialState();
      this.selectActiveBids();
    }

    makeInitialState(): ButtonState[] {
      return [3, 2, 1, 0, null]
        .map((bidNum) => {
          const bid: BiddingResult = {
            hold: false,
            bid: bidNum,
            gameOver: false,
            invitation: null,
            lastBid: false,
            tryForHonor: false
          };
          const text = (lc: LC) => lc.phase.bidding.bid(bidNum);
          return {text: text, bid: bid, active: false}
        })
    }

    doBid(bid: BiddingResult) {
      this.gameController.bid(bid.bid);
      this.buttonState.forEach(state => state.active = false);
    }
  }
