
  import {Component, Inject, Prop, Vue, Watch} from 'vue-property-decorator';
  import Hand from './Hand.vue';
  import {Card} from '../../data/card';
  import {UserRepo} from '../../control/userRepo';
  import {PlayerPosition} from '../../data/gamestate';
  import {LanguageConfig} from "../../language/languages";

  @Component({
    components: {Hand},
  })
  export default class PlayerBox extends Vue {
    @Inject() lc!: LanguageConfig;
    @Inject() userRepo!: UserRepo;
    @Prop({required: true}) cardsInHand!: Array<Card | null>[];
    @Prop({
      default: function () {
        return [];
      }
    }) activeCards!: Card[];
    @Prop({required: true}) playerId!: string;
    @Prop({default: false}) isDealer!: boolean;
    @Prop({default: false}) isPlayer!: boolean;
    @Prop() position!: PlayerPosition;
    @Prop() bubbleText!: string;
    private bubbleShown: boolean = false;

    private timeOutId: number | null = null;

    @Watch('bubbleText')
    private updateBubble() {
      if (this.timeOutId) {
        window.clearTimeout(this.timeOutId);
      }
      this.bubbleShown = true;
      this.timeOutId = window.setTimeout(() => {
        this.bubbleShown = false;
        this.timeOutId = null;
      }, 15000);
    }

    get userName() {
      return this.userRepo.getUserName(this.playerId) ?? this.playerId;
    }

    get role() {
      if (this.isDealer) {
        return this.lc.text.ui.dealer;
      }
      if (this.isPlayer) {
        return this.lc.text.ui.player;
      }
      // nbsp
      return '\xa0';
    }
  }
