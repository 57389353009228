import { en } from './en';
import { hu } from './hu';

export class LanguageConfig {
  private _language = 'hu';
  private available: {[key: string]: typeof hu.text} = {
    'hu': hu.text,
    'en': en.text
  };

  constructor() {
    this.setHtmlLang();
  }

  get text(): typeof hu.text {
    return this.available[this._language];
  }

  set language(value: string) {
    this._language = value;
    this.setHtmlLang();
  }

  private setHtmlLang() {
    const doc = document?.documentElement;
    doc?.setAttribute('lang', this._language);
  }
}

export type LC = typeof hu.text;